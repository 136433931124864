import axios from 'axios'
import Immutable from 'immutable'
import qs from 'qs'

import { getIsManager, STATUS_ACTIVE } from './components/project-helpers'
import { getJson, postJson, uploadFile } from '../common/services/http'
import { navRenameProject, navRemoveProject, navRemoveProjectFolders,
  navAddProjectFolder, navRenameProjectFolder, navMoveProjectFolders,
  navSetStar, navAddProject } from '../container/components/desktop/nav-actions'
import { promptTextDialog, promptConfirmDialog } from '../container/services/dialog'

export const PROJECT_LOADED = 'PROJECT/PROJECT_LOADED'
export const SET_PROJECT_LOCATION = 'PROJECT/SET_PROJECT_LOCATION'
export const CLEAR_PROJECT = 'PROJECT/CLEAR_PROJECT'
export const RENAME_PROJECT = 'PROJECT/RENAME_PROJECT'
export const ADD_ITEM = 'PROJECT/ADD_ITEM'
export const RENAME_FILE = 'PROJECT/RENAME_FILE'
export const UPDATE_FILE_STATUS = 'PROJECT/UPDATE_FILE_STATUS'
export const REMOVE_FILES = 'PROJECT/REMOVE_FILES'
export const MOVE_FILE_TO_POSITION = 'PROJECT/MOVE_FILE_TO_POSITION'
export const MOVE_FILES_TO_FOLDER = 'PROJECT/MOVE_FILES_TO_FOLDER'
export const ADD_MEMBERS = 'PROJECT/ADD_MEMBERS'
export const REMOVE_MEMBER = 'PROJECT/REMOVE_MEMBER'
export const SET_OWNER = 'PROJECT/SET_OWNER'
export const TOGGLE_SELECTED_FILE = 'PROJECT/TOGGLE_SELECTED_FILE'
export const TOGGLE_SELECTED_FILE_RANGE = 'PROJECT/TOGGLE_SELECTED_FILE_RANGE'
export const CLEAR_SELECTED_FILES = 'PROJECT/CLEAR_SELECTED_FILES'
export const CLEAR_OTHER_SELECTED_FILES = 'PROJECT/CLEAR_OTHER_SELECTED_FILES'
export const SHOW_FILE_PLACEHOLDER = 'PROJECT/SHOW_FILE_PLACEHOLDER'
export const TOGGLE_STAR = 'PROJECT/TOGGLE_STAR'
export const SET_STATUS = 'PROJECT/SET_STATUS'

const generateUUID = a => a?(a^(Math.random()*16>>a/4)).toString(16):([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g,generateUUID)

export function projectLoaded(project) {
  document.title = `Docollab | ${project.get('name')}`
  return {
    type: PROJECT_LOADED,
    project
  }
}

export function clearProject() {
  return {
    type: CLEAR_PROJECT
  }
}

export const setProjectLocation = ({folderId, itemId, isTaskTree, isMembers, isSearch}) =>
  (dispatch, getState) => {
    const project = getState().project
    const projectId = project.get('id')
    const pathname = `/project/${projectId}`
    if(folderId && !project.get('files').find(file => file.get('id') === folderId && file.get('type') === 'folder')) {
      window.browserHistory.push(pathname)
    } else if(itemId && !project.get('files').find(file => file.get('id') === itemId)) {
      window.browserHistory.push({
        pathname,
        search: qs.stringify({
          folder: folderId || undefined
        })
      })
    } else {
      dispatch({
        type: SET_PROJECT_LOCATION,
        folderId,
        itemId,
        isTaskTree,
        isMembers,
        isSearch
      })
    }
}

export function promptRenameProject() {
  return (dispatch, getState) => {
    const project = getState().project
    promptTextDialog({
      title: 'Rename Project',
      fieldLabel: 'Name',
      defaultValue: project.get('name'),
      actionLabel: 'Rename',
      required: true
    }).then(name => {
      if(name === project.get('name')) {
        return
      }
      const id = project.get('id')
      postJson(`/jsapi/s/projects/${id}/rename`, {value: name})
      dispatch({
        type: RENAME_PROJECT,
        name
      })
      dispatch(navRenameProject({id, name}))
    })
  }
}

export function addItem(item) {
  return {
    type: ADD_ITEM,
    item
  }
}

export function promptCreateFolder(name) {
  return (dispatch, getState) =>
    promptTextDialog({
      title: 'Create a Folder',
      fieldLabel: 'Name',
      defaultValue: 'New Folder',
      actionLabel: 'Create'
    }).then(name => {
      const project = getState().project
      const projectId = project.get('id')
      const folderId = project.get('currentFolderId')

      const id = generateUUID()
      const folder = Immutable.fromJS({
        type: 'folder',
        id,
        name: name || 'New Folder',
        folderId,
        index: project.get('files').filter(item => item.get('folderId') === (project.get('currentFolderId') || null)).size
      })
      postJson(`/jsapi/s/projects/${projectId}/createfolder`, folder)
      dispatch(addItem(folder))
      dispatch(navAddProjectFolder({
        projectId,
        folder
      }))
      window.browserHistory.push(`/project/${projectId}?folder=${id}`)
    })
}

export function promptCreateDocument() {
  return (dispatch, getState) =>
    promptTextDialog({
      title: 'Create a Document',
      fieldLabel: 'Name',
      defaultValue: 'New Document',
      actionLabel: 'Create'
    }).then(name => {
      const project = getState().project
      const projectId = project.get('id')
      const folderId = project.get('currentFolderId')
      dispatch({type: SHOW_FILE_PLACEHOLDER})
      axios.post(`/jsapi/s/projects/${projectId}/adddocument`, {folderId: folderId, name: name}).then(({data}) => {
        dispatch(addItem(Immutable.fromJS(data)))
        window.browserHistory.push(`/project/${projectId}?${qs.stringify({folder: folderId || undefined, item: data.id})}`, {fullScreen: true})
      })
    })
}

export function promptCreateSpreadsheet(name) {
  name += '.xlsx'
  return (dispatch, getState) =>
    promptTextDialog({
      title: 'Create a Spreadsheet',
      fieldLabel: 'Name',
      defaultValue: 'New Spreadsheet',
      actionLabel: 'Create'
    }).then(name => {
      if(!name.endsWith('.xlsx')) {
        name += '.xlsx'
      }
      const project = getState().project
      const projectId = project.get('id')
      const folderId = project.get('currentFolderId')
      dispatch({type: SHOW_FILE_PLACEHOLDER})
      axios.post(`/jsapi/s/projects/${projectId}/addspreadsheet`, {folderId: folderId, name: name}).then(({data}) => {
        dispatch(addItem(Immutable.fromJS(data)))
        window.browserHistory.push(`/project/${projectId}?${qs.stringify({folder: folderId || undefined, item: data.id})}`, {fullScreen: true})
      })
    })
}

export function fetchProject({id, folderId, itemId, isTaskTree, isMembers}) {
  return dispatch => {
    dispatch({type: CLEAR_PROJECT})
    getJson(`/jsapi/s/projects/${id}`).then(response => {
      let project = response
        .set('currentFolderId', folderId || null)
        .set('currentItemId', itemId)
        .set('isTaskTree', isTaskTree)
        .set('isMembers', isMembers)
      if(folderId) {
        const folder = project.get('files').find(file => file.get('id') === folderId && file.get('type') === 'folder')
        if(!folder) {
          window.browserHistory.push(`/project/${id}`)
          dispatch(projectLoaded(project.set('currentFolderId', null).set('currentItemId', null)))
          return
        }
      }
      if(itemId) {
        const file = project.get('files').find(file => file.get('id') === itemId)
        if(!file) {
          window.browserHistory.push(`/project/${id}`)
          dispatch(projectLoaded(project.set('currentFolderId', null).set('currentItemId', null)))
          return
        }
        const index = project.get('files').indexOf(file)
        project = project
          .set('lastSelectedIndex', file.get('index'))
          .setIn(['files', index, 'selected'], true)
      }
      dispatch(projectLoaded(project))
    }).catch(() => window.browserHistory.push('/list'))
  }
}

export function promptRenameFile(file) {
  const title = (() => {
    switch(file.get('type')) {
      case 'folder': return 'Rename Folder'
      default: return 'Rename File'
    }
  })()
  return (dispatch, getState) =>
    promptTextDialog({
      title,
      fieldLabel: 'Name',
      defaultValue: file.get('name'),
      actionLabel: 'Rename',
      required: true
    }).then(name => {
      if(name === file.get('name')) {
        return
      }
      const projectId = getState().project.get('id')
      postJson(`/jsapi/s/projects/${projectId}/renameitem/${file.get('id')}`, { value: name })
      dispatch({
        type: RENAME_FILE,
        id: file.get('id'),
        name
      })
      if(file.get('type') === 'folder') {
        dispatch(navRenameProjectFolder({
          projectId,
          folderId: file.get('id'),
          name
        }))
      }

      if(window.$) {
        window.$(`[data-ref="file:${file.get('id')}"] .file-widget-name`).text(name)
      }

    })
}

export const updateFileStatus = (file, status) => {
  if(file.get('status') === status) {
    return
  }
  return (dispatch, getState) => {
    const projectId = getState().project.get('id')
    const id = file.get('id')
    axios.post(`/jsapi/s/projects/${projectId}/files/${id}/status`, { value: status })
    dispatch({
      type: UPDATE_FILE_STATUS,
      id,
      status
    })
  }
}

export function promptDeleteFiles(files) {
  const {title, text, actionLabel} = (() => {
    if(files.size > 1) {
      return {
        title: 'Delete Items',
        text: `Do you want to delete ${files.size} selected items?`,
        actionLabel: 'Delete'
      }
    }
    const file = files.first()
    switch(file.get('type')) {
      case 'folder':
        return {
          title: 'Delete Folder',
          text: `Do you want to delete "${file.get('name')}" and all its content?`,
          actionLabel: 'Delete'
        }
      default:
        return {
          title: 'Delete File',
          text: `Do you want to delete "${file.get('name')}" ?`,
          actionLabel: 'Delete'
        }
    }
  })()
  return (dispatch, getState) =>
    promptConfirmDialog({title, text, actionLabel}).then(() => {
      const project = getState().project
      const projectId = project.get('id')

      const deletedCurrentFolder = files.find(f => f.get('id') === project.get('currentFolderId'))
      const ids = files.map(f => f.get('id'))
      const deletedCurrentFile = ids.includes(project.get('currentItemId'))

      if(deletedCurrentFolder) {
        window.browserHistory.push({
          pathname: `/project/${projectId}`,
          search: qs.stringify({
            folder: deletedCurrentFolder.get('folderId') || undefined
          })
        })
      } else if(deletedCurrentFile) {
        window.browserHistory.push({
          search: qs.stringify({
            folder: project.get('currentFolderId') || undefined
          })
        })
      }

      postJson(`/jsapi/s/projects/${projectId}/files/delete`, ids)
      dispatch({
        type: REMOVE_FILES,
        ids
      })

      const folderIds = files
        .filter(f => f.get('type') === 'folder')
        .map(f => f.get('id'))
      if(folderIds) {
        dispatch(navRemoveProjectFolders({
          projectId,
          folderIds
        }))
      }
    })
}

export const removeFiles = ids => ({
  type: REMOVE_FILES,
  ids
})

export function moveFileToPosition({id, newIndex}) {
  return (dispatch, getState) => {
    const projectId = getState().project.get('id')
    postJson(`/jsapi/s/projects/${projectId}/files/${id}/movetoposition`, { value: newIndex })
    dispatch({
      type: MOVE_FILE_TO_POSITION,
      id,
      newIndex
    })
  }
}

export function promptRestore() {
  return (dispatch, getState) => {
    const project = getState().project
    promptConfirmDialog({
      title: 'Restore Project',
      text: 'Do you want to restore this project?',
      actionLabel: 'Restore',
      danger: false
    }).then(() => {
      const id = project.get('id')
      postJson(`/jsapi/s/projects/${id}/restore`)
      dispatch({
        type: SET_STATUS,
        status: STATUS_ACTIVE
      })
      const name = project.get('name')
      dispatch(navAddProject(Immutable.fromJS({id, name})))
    })
  }
}

export function promptArchive() {
  return (dispatch, getState) => {
    const project = getState().project
    promptConfirmDialog({
      title: 'Archive Project',
      text: 'Do you want to archive this project?',
      actionLabel: 'Archive',
      danger: false
    }).then(() => {
      const id = project.get('id')
      const notification = window.notificationSystem.addNotification({
        message: 'Archiving...',
        position: 'tc',
        level: 'warning',
        autoDismiss: 0
      })
      postJson(`/jsapi/s/projects/${id}/archive`).then(() => {
        window.notificationSystem.removeNotification(notification)
        window.browserHistory.push('/list')
        dispatch({type: CLEAR_PROJECT})
        dispatch(navRemoveProject(id))
      })
    })
  }
}

export function promptDeleteOrLeaveProject() {
  return (dispatch, getState) => {
    const project = getState().project
    const isManager = getIsManager(project)
    promptConfirmDialog({
      title: isManager ? 'Move to Trash' : 'Leave Project',
      text: isManager ?
        'Do you want to move this project to trash?' :
        'Do you want to leave this project?',
      actionLabel: isManager ? 'Move to trash' : 'Leave'
    }).then(() => {
      const id = project.get('id')
      const notification = window.notificationSystem.addNotification({
        message: isManager ? 'Moving to trash...' : 'Leaving...',
        position: 'tc',
        level: 'error',
        autoDismiss: 0
      })
      const endpoint = isManager ? 'delete' : 'leave'
      postJson(`/jsapi/s/projects/${id}/${endpoint}`).then(() => {
        window.notificationSystem.removeNotification(notification)
        window.browserHistory.push('/list')
        dispatch({type: CLEAR_PROJECT})
        dispatch(navRemoveProject(id))
      })
    })
  }
}

export const promptDeletePermanently = () =>
  (dispatch, getState) => {
    const project = getState().project
    promptConfirmDialog({
      title: 'Delete Project Permanently',
      text: 'Do you want to permanently delete this project? This operation is irreversible!',
      actionLabel: 'Delete Permanently',
      danger: false
    }).then(() => {
      const id = project.get('id')
      const notification = window.notificationSystem.addNotification({
        message: 'Deleting...',
        position: 'tc',
        level: 'error',
        autoDismiss: 0
      })
      postJson(`/jsapi/s/projects/${id}/deleteperm`).then(() => {
        window.notificationSystem.removeNotification(notification)
        window.browserHistory.push('/list')
        dispatch({type: CLEAR_PROJECT})
      })
    })
  }

export function moveToFolder({files, folderId}) {
  return (dispatch, getState) => {
    const projectId = getState().project.get('id')
    const ids = files.map(f => f.get('id'))
    postJson(`/jsapi/s/projects/${projectId}/movetofolder`, {ids, folderId})
    dispatch({
      type: MOVE_FILES_TO_FOLDER,
      ids,
      folderId
    })
    if(files.some(f => f.get('type') === 'folder')) {
      dispatch(navMoveProjectFolders({projectId, ids, folderId}))
    }
  }
}

export function addMembers(userIds, level) {
  return (dispatch, getState) => {
    const state = getState().project
    const projectId = state.get('id')
    const notification = window.notificationSystem.addNotification({
      message: 'Adding members...',
      position: 'tc',
      level: 'info',
      autoDismiss: 0
    })
    postJson(`/jsapi/s/projects/${projectId}/members`, { userIds, level }).then(lists => {
      window.notificationSystem.removeNotification(notification)
      dispatch({
        type: ADD_MEMBERS,
        lists
      })
    })
  }
}

export function promptRemoveMember(user) {
  return (dispatch, getState) => {
    promptConfirmDialog({
      title: 'Remove Member',
      text: `Do you want to remove ${user.get('name')} from the project?`,
      actionLabel: 'Remove'
    }).then(() => {
      const state = getState().project
      const projectId = state.get('id')
      const userId = user.get('id')
      postJson(`/jsapi/s/projects/${projectId}/members/${userId}/remove`)
      dispatch({
        type: REMOVE_MEMBER,
        user
      })
    })
  }
}

export function transferOwnership(userId) {
  return (dispatch, getState) => {
    const state = getState().project
    const projectId = state.get('id')
    postJson(`/jsapi/s/projects/${projectId}/owner`, { value: userId })
    dispatch({
      type: SET_OWNER,
      userId
    })
  }
}

export function toggleSelectedFile(id) {
  return {
    type: TOGGLE_SELECTED_FILE,
    id
  }
}

export function toggleSelectedFileRange(id) {
  return {
    type: TOGGLE_SELECTED_FILE_RANGE,
    id
  }
}

export function clearSelectedFiles() {
  return {
    type: CLEAR_SELECTED_FILES
  }
}

export function clearOtherSelectedFiles(file) {
  return {
    type: CLEAR_OTHER_SELECTED_FILES,
    file
  }
}

export const uploadSelectedFiles = (files, callback) =>
  (dispatch, getState) => {
    if(files && files.length) {
      const project = getState().project
      const projectId = project.get('id')
      const folderId = project.get('currentFolderId')
      const path = `/jsapi/s/projects/${projectId}/uploadurl/${folderId || 'root'}`
      Array.from(files)
        .map(file => uploadFile(path, file)
        .then(file => {
          dispatch(addItem(file))
          if(callback) {
            callback(file)
          }
        }))
    }
  }

export const toggleStar = () =>
  (dispatch, getState) => {
    const project = getState().project
    const projectId = project.get('id')
    const value = !project.get('star')
    postJson(`/jsapi/s/projects/${projectId}/star`, {value})
    dispatch({type: TOGGLE_STAR})
    dispatch(navSetStar(projectId, value))
  }
