import { Map } from 'immutable'

import * as ACTION from './container-actions'

export default function containerReducer(state = Map({loaded: false, message: ''}), action) {
  switch(action.type) {
  case ACTION.LOAD_APP_VIEW: {
    return state
      .set('loaded', true)
      .set('user', action.user)
  }
  case ACTION.SET_USER:
    return state.set('user', action.user)
  case ACTION.UPDATE_USER_NAME:
    return state.update('user', user => user.merge({
      firstName: action.firstName,
      lastName: action.lastName,
      fullName: `${action.firstName} ${action.lastName}`,
      initials: (action.firstName.charAt(0) + action.lastName.charAt(0)).toUpperCase()
    }))
  case ACTION.UPDATE_USER_PHOTO:
    return state.setIn(['user', 'photoUrl'], action.photoUrl)
  case ACTION.CLEAR_MESSAGE:
    return state
      .set('message', '')
      .delete('messageTimeout')
  case ACTION.SHOW_MESSAGE:
    return state
      .set('message', action.message)
      .set('messageTimeout', action.noTimeout ? undefined: Date.now() + 1200)
  // case REMOVE_INCOMING:
  //   return state.updateIn(['user', 'incoming'], incoming => incoming - 1)
  case ACTION.SET_ENTERPRISE:
    return state
      .setIn(['user', 'enterprise'], true)
      .setIn(['user', 'orgAdmin'], true)
  case ACTION.SET_STRIPE_KEY:
    return state.set('stripeKey', action.stripeKey)
  default:
    return state
  }
}
