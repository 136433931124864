import axios from 'axios'

import { clearMessage, showMessage } from '../container/container-actions'

export const SET_OPEN = 'INVITE/SET_OPEN'
export const SET_MESSAGE = 'INVITE/SET_MESSAGE'
export const INVITATION_SENT = 'INVITE/INVITATION_SENT'

export function openInviteDialog() {
  return {
    type: SET_OPEN,
    open: true
  }
}

export function closeInviteDialog() {
  return {
    type: SET_OPEN,
    open: false
  }
}

function setMessage(message) {
  return {
    type: SET_MESSAGE,
    message
  }
}

export function sendInvitation(email) {
  return dispatch => {
    dispatch(showMessage(`Sending invitation to ${email}`))
    dispatch(setMessage(undefined))
    axios.post('/jsapi/s/network/invite', { value: email }).then(invitation => {
      dispatch(clearMessage())
      if(invitation.get('error')) {
        switch(invitation.get('error')) {
          case 'exists':
            dispatch(setMessage({text: `Pending invitation to ${email} exists`}))
            break
          case 'self':
            dispatch(setMessage({error: true, text: 'You cannot invite yourself'}))
            break
          case 'colleague':
            dispatch(setMessage({error: true, text: `${email} is the e-mail of one of your exiting colleagues`}))
            break
          default:
            dispatch(setMessage({error: true, text: `Error sending invitation to ${email}`}))
        }
      } else {
        dispatch({
          type: INVITATION_SENT,
          invitation
        })
        dispatch(setMessage({text: `Invitation sent to ${email}`}))
      }
    })
  }
}
