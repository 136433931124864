import { getJson, postJson } from '../../../common/services/http'
import { promptTextDialog } from '../../services/dialog'
import { TOGGLE_STAR } from '../../../project/project-actions'

export const SET_NAV = 'NAV/SET_NAV'
export const PROJECT_STRUCTURE_LOADED = 'NAV/PROJECT_STRUCTURE_LOADED'
export const ADD_PROJECT = 'NAV/ADD_PROJECT'
export const RENAME_PROJECT = 'NAV/RENAME_PROJECT'
export const REMOVE_PROJECT = 'NAV/REMOVE_PROJECT'
export const ADD_PROJECT_FOLDER = 'NAV/ADD_PROJECT_FOLDER'
export const REMOVE_PROJECT_FOLDERS = 'NAV/REMOVE_PROJECT_FOLDERS'
export const RENAME_PROJECT_FOLDER = 'NAV/RENAME_PROJECT_FOLDER'
export const MOVE_PROJECT_FOLDERS = 'NAV/MOVE_PROJECT_FOLDERS'
export const SET_STAR = 'NAV/SET_STAR'
export const MOVE_PROJECT = 'NAV/MOVE_PROJECT'

export function loadNav() {
  return dispatch =>
    getJson('/jsapi/s/list/nav').then(nav => dispatch({
      type: SET_NAV,
      nav
    }))
}

export function loadProjectStructure(id) {
  return dispatch =>
    getJson(`/jsapi/s/projects/${id}/structure`).then(result => dispatch({
      type: PROJECT_STRUCTURE_LOADED,
      id,
      result
    }))
}

export function promptCreateProject() {
  return dispatch => {
    promptTextDialog({
      title: 'New Project',
      fieldLabel: 'Name',
      defaultValue: 'New Project',
      actionLabel: 'Create'
    }).then(name =>
      postJson('/jsapi/s/projects/create', {value: name}).then(project => {
        dispatch(navAddProject(project))
        window.browserHistory.push(`/project/${project.get('id')}`)
      })
    )
  }
}

export const navAddProject = project => ({
  type: ADD_PROJECT,
  project: project.set('star', false)
})

export function navRenameProject({id, name}) {
  return {
    type: RENAME_PROJECT,
    id,
    name
  }
}

export function navAddProjectFolder({projectId, folder}) {
  return {
    type: ADD_PROJECT_FOLDER,
    projectId,
    folder
  }
}

export function navRenameProjectFolder({projectId, folderId, name}) {
  return {
    type: RENAME_PROJECT_FOLDER,
    projectId,
    folderId,
    name
  }
}

export function navRemoveProject(id) {
  return {
    type: REMOVE_PROJECT,
    id
  }
}

export function navRemoveProjectFolders({projectId, folderIds}) {
  return {
    type: REMOVE_PROJECT_FOLDERS,
    projectId,
    folderIds
  }
}

export function navMoveProjectFolders({projectId, ids, folderId}) {
  return {
    type: MOVE_PROJECT_FOLDERS,
    projectId,
    ids,
    folderId
  }
}

export const toggleStar = (projectId, star) =>
  (dispatch, getState) => {
    postJson(`/jsapi/s/projects/${projectId}/star`, { value: star })
    dispatch(navSetStar(projectId, star))

    const editProject = getState().project
    if(editProject && editProject.get('id') === projectId) {
      dispatch({type: TOGGLE_STAR})
    }
  }

export const navSetStar = (id, star) => ({
  type: SET_STAR,
  id,
  star
})

export const moveProject = ({projectId, listType, index, targetIndex}) =>
  dispatch => {
    postJson(`/jsapi/s/list/moveproject`, {projectId, listType, index, targetIndex})
    dispatch({
      type: MOVE_PROJECT,
      listType,
      index,
      targetIndex
    })
  }
