import axios from 'axios'

export const LOAD_APP_VIEW = 'CONTAINER/LOAD_APP_VIEW'
export const SET_USER = 'CONTAINER/SET_USER'
export const UPDATE_USER_NAME = 'CONTAINER/UPDATE_USER_NAME'
export const UPDATE_USER_PHOTO = 'CONTAINER/UPDATE_USER_PHOTO'
export const CLEAR_MESSAGE = 'CONTAINER/CLEAR_MESSAGE'
export const SHOW_MESSAGE = 'CONTAINER/SHOW_MESSAGE'
export const SET_ENTERPRISE = 'CONTAINER/SET_ENTERPRISE'
export const SET_STRIPE_KEY = 'CONTAINER/SET_STRIPE_KEY'

export const loadAppView = user =>
  (dispatch, getState) =>
    dispatch({
      type: LOAD_APP_VIEW,
      user: user || getState().container.get('user')
    })

export function signin(user) {

  const token = user.get('token')
  localStorage.setItem('token', token)

  const isNew = user.get('isNew')
  return {
    type: SET_USER,
    user
  }
}

export const signout = () =>
  dispatch => {
    const googleAuth = window.gapi.auth2.getAuthInstance()
    if(!!googleAuth && googleAuth.isSignedIn.get()) {
      googleAuth.signOut()
    }
    axios.post('/jsapi/o/session/signout')
      .then(() => dispatch({
        type: SET_USER,
        user: null
      }))
      .finally(() => localStorage.removeItem('token'))
  }

export function clearMessage() {
  return (dispatch, getState) => {
    const messageTimeout = getState().container.get('messageTimeout')
    const now = Date.now()
    if(!!messageTimeout && messageTimeout > now) {
      setTimeout(() => dispatch({
        type: CLEAR_MESSAGE
      }), messageTimeout - now)
    } else {
      dispatch({
        type: CLEAR_MESSAGE
      })
    }
  }
}

export function showMessage(message, noTimeout) {
  return {
    type: SHOW_MESSAGE,
    message,
    noTimeout
  }
}

export function messageSaving() {
  return showMessage('Saving...')
}

export function messageDeleting() {
  return showMessage('Deleting...')
}

export function messageRemoving() {
  return showMessage('Removing...')
}

export function messageUploading() {
  return showMessage('Uploading...', true)
}

export function setEnterprise() {
  return {
    type: SET_ENTERPRISE
  }
}

export function setStripeKey(stripeKey) {
  return {
    type: SET_STRIPE_KEY,
    stripeKey
  }
}

export const updateUserName = ({firstName, lastName}) => ({
  type: UPDATE_USER_NAME,
  firstName,
  lastName
})

export const updateUserPhoto = (photoUrl) => ({
  type: UPDATE_USER_PHOTO,
  photoUrl
})
