import React, { Component } from 'react'
import is from 'is_js'
import { connect } from 'react-redux'
import { Div } from 'glamorous'
import { red800, green800 } from 'material-ui/styles/colors'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'

import { sendInvitation, closeInviteDialog } from './invite-actions'

class InviteDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    if(!this.props.invite.get('open') && nextProps.invite.get('open')) {
      this.focus()
    }
  }

  focus() {
    setTimeout(() => this.refs.email.focus(), 200)
  }

  close = () =>
    this.props.dispatch(closeInviteDialog())

  isInvalidEmail() {
    return !is.email(this.state.email)
  }

  send = () => {
    this.focus()
    if(this.isInvalidEmail()) {
      return
    }
    this.props.dispatch(sendInvitation(this.state.email))
    this.setState({email: ''})
  }

  render() {
    return(
      <Dialog open={this.props.invite.get('open')} title="Invite Colleagues" autoScrollBodyContent onRequestClose={this.close}
        actions={
          <FlatButton label="Close" secondary onClick={this.close}/>
        }>
        <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            marginTop: 20
          }}
        >
          <TextField
            ref="email"
            type="email"
            fullWidth
            hintText="E-main address"
            value={this.state.email}
            onChange={event => this.setState({email: event.target.value})}
            onKeyDown={event => {if(event.keyCode === 13) this.send()}}
            style={{marginRight: '20px'}}
          />
          <RaisedButton primary label="Send invitation" disabled={this.isInvalidEmail()} onClick={this.send} style={{width: '250px'}}/>
        </div>
        <p>Docollab will send an invitation to the e-mail you enter, and once your colleague accepts it, you will be able to share content with each other. You can invite any number of colleagues.</p>
        <div style={{
          minHeight: 16,
          marginTop: 10,
          fontWeight: 'bold'
        }}>
          {this.props.invite.get('message') &&
            <Div
              color={this.props.invite.get('message').error ? red800 : green800}
            >{this.props.invite.get('message').text}</Div>
          }
        </div>
      </Dialog>
    )
  }
}

export default connect(function(state) {
  return {
    invite: state.invite
  }
})(InviteDialog)
