import Immutable from 'immutable'

import * as ACTION from './invite-actions'

export default function inviteReducer(state = Immutable.Map({open: false}), action) {

  switch(action.type) {
    case ACTION.SET_OPEN:
      return state
        .set('open', action.open)
        .delete('message')
    case ACTION.SET_MESSAGE:
      return state.set('message', action.message)
    default:
      return state
  }
}
