import Immutable from 'immutable'
import moment from 'moment'
import axios from 'axios'

moment.fn.toJSON = function() {
  return this.valueOf()
}

export const baseURL = process.env.REACT_APP_API_BASE || ''

export const postJson = async (path, payload) => {
  const { data } = await axios.post(baseURL + path, payload)
  return Immutable.fromJS(data)
}

export const getJson = async path => {
  const { data } = await axios.get(baseURL + path)
  return Immutable.fromJS(data)
}

export function uploadFile(path, file) {
  return new Promise((resolve, reject) => {

    const filename = file.name
    const notification = window.notificationSystem.addNotification({
      message: `Uploading ${filename}`,
      position: 'br',
      level: 'info',
      autoDismiss: 0
    })

    axios.post(baseURL + path)
    .then(({ data: url }) => {
      var formData = new FormData()
      formData.append('file', file)
      var xhr = new XMLHttpRequest()
      xhr.open('POST', url, true)
      xhr.onload = function() {
        window.notificationSystem.removeNotification(notification)
        if(xhr.status === 200) {
          resolve(Immutable.fromJS(JSON.parse(xhr.responseText)))
        } else {
          window.notificationSystem.addNotification({
            message: `Error uploading ${file.name}`,
            position: 'br',
            level: 'error',
            autoDismiss: 0
          })
          reject(Immutable.fromJS(JSON.parse(xhr.responseText)))
        }
      }
      xhr.send(formData)
    })
  })
}

export const downloadFile = (path, flexibleModule) => {
  const iframe = document.getElementById('docollab-download-iframe')
  iframe.src = 'about:blank'
  const envBaseURL = (process.env.NODE_ENV === 'development' && flexibleModule) ? 'http://localhost:8081' : baseURL
  setTimeout(() => iframe.src = `${envBaseURL}${path}${path.includes('?') ? '&' : '?'}t=${localStorage.getItem('token')}`)
}
