// @ts-nocheck

import 'mdi/css/materialdesignicons.min.css'
import 'material-design-iconic-font/dist/css/material-design-iconic-font.css'
import 'font-awesome/css/font-awesome.css'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import is from 'is_js'
import createBrowserHistory from 'history/createBrowserHistory'
import axios from 'axios'
// import runtime from 'serviceworker-webpack-plugin/lib/runtime'

import './index.css'
import configureStore from './store'
import AppContainer from './container/components/AppContainer'
import HistoryContentPreview from "./history/HistoryContentPreview";
import DesktopRouter from "./container/components/desktop/DesktopRouter";
import MobileRouter from "./container/components/mobile/MobileRouter";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE || ''

axios.interceptors.request.use(config => {
  config.headers['Accept'] = `application/json;t=${localStorage.getItem('token')}`
  config.headers['Content-Type'] = 'text/plain'
  return config
})

const store = configureStore()

if(navigator.userAgent.toLowerCase().indexOf('googlebot') > -1) {
  window.googlebot = true
}

window.browserHistory = createBrowserHistory()

if(is.desktop()) {
  if(window.location.pathname === '/history') {
    import('./history/HistoryContentPreview').then(({ default: HistoryContentPreview }) => render(
        <HistoryContentPreview/>, document.getElementById('root')
    ))
  } else {
    import('./container/components/desktop/DesktopRouter').then(({ default: DesktopRouter }) =>
      render(
        <Provider store={store}>
          <AppContainer>
            <DesktopRouter/>
          </AppContainer>
        </Provider>, document.getElementById('root')
      ))
  }
} else {
  import('./container/components/mobile/MobileRouter').then(({ default: MobileRouter }) =>
    render(
      <Provider store={store}>
        <AppContainer>
          <MobileRouter/>
        </AppContainer>
      </Provider>, document.getElementById('root')
    ))
}
