import Immutable from 'immutable'

import * as ACTION from './nav-actions'

const updateProject = (state, id, update) =>
  state.update('projects', projects =>
    projects.mapEntries(([key, list]) =>
      [key, list.map(project =>
        project.get('id') === id ? update(project) : project
      )]
    )
  )

const removeProject = (state, id) =>
  state.update('projects', projects =>
    projects.map(list =>
      list.filter(project => project.get('id') !== id)
    )
  )

const updateFolders = (state, projectId, update) =>
  updateProject(state, projectId, project =>
    project.get('folders') ? project.update('folders', update) : project)

export default function containerReducer(state = null, action) {
  switch(action.type) {
  case ACTION.SET_NAV:
    return action.nav
  case ACTION.PROJECT_STRUCTURE_LOADED: {
    switch(action.result.get('status')) {
      case 'notfound':
        return removeProject(state, action.id)
      case 'changed':
        return updateProject(state, action.id, () => action.result.get('project'))
      default:
        return state
    }
  }
  case ACTION.ADD_PROJECT:
    return state.updateIn(['projects', 'unstarred'], projects => projects.unshift(action.project))
  case ACTION.RENAME_PROJECT:
    return updateProject(state, action.id, project => project.set('name', action.name))
  case ACTION.SET_STAR: {
    const projects = state.get('projects')
    const removeFromKey = action.star ? 'unstarred' : 'starred'
    let removeFromList = projects.get(removeFromKey) || Immutable.List()
    const addToKey = action.star ? 'starred' : 'unstarred'
    let addToList = projects.get(addToKey) || Immutable.List()
    const project = removeFromList.find(p => p.get('id') === action.id)
    removeFromList = removeFromList.filter(p => p.get('id') !== action.id)
    addToList = addToList.unshift(project)
    return state.set('projects',
      Immutable.Map()
        .set(removeFromKey, removeFromList)
        .set(addToKey, addToList))
  }
  case ACTION.REMOVE_PROJECT:
    return removeProject(state, action.id)
  case ACTION.ADD_PROJECT_FOLDER:
    return updateFolders(state, action.projectId, folders => folders.push(action.folder))
  case ACTION.REMOVE_PROJECT_FOLDERS:
    return updateFolders(state, action.projectId, folders => {
      action.folderIds.forEach(id => {
        const index = folders.findIndex(folder => folder.get('id') === id)
        if(index > -1) {
          folders = folders.delete(index)
        }
      })
      return folders
    })
  case ACTION.RENAME_PROJECT_FOLDER:
    return updateFolders(state, action.projectId, folders =>
      folders.map(folder =>
        folder.get('id') === action.folderId ? folder.set('name', action.name) : folder))
  case ACTION.MOVE_PROJECT_FOLDERS:
    return updateFolders(state, action.projectId, folders => {

      const foldersToMove = folders
        .filter(f => action.ids.includes(f.get('id')))
        .map(f => f.set('folderId', action.folderId))

      let groups = folders
        .filter(f => !action.ids.includes(f.get('id')))
        .groupBy(f => f.get('folderId'))

      if(!groups.has(action.folderId)) {
        groups = groups.set(action.folderId, Immutable.List())
      }

      return groups
        .map((folders, folderId) => folderId === action.folderId ? folders.splice(0, 0, ...foldersToMove.toArray()) : folders)
        .map(f => f.sortBy(f => f.get('index')).map((file, index) => file.set('index', index)))
        .valueSeq()
        .flatten(1)
      })
  case ACTION.MOVE_PROJECT:
    return state.updateIn(['projects', action.listType], list => list
      .delete(action.index)
      .splice(action.targetIndex, 0, list.get(action.index)))
  default:
    return state
  }
}
