import { List } from 'immutable'

import { getJson, postJson } from '../common/services/http'

export const ORGANIZATION_LOADED = 'ORGANIZATION/ORGANIZATION_LOADED'
export const CLEAR_ORGANIZATION = 'ORGANIZATION/CLEAR_ORGANIZATION'
export const SET_POLICY = 'ORGANIZATION/SET_POLICY'
export const RENAME_ORGANIZATION = 'ORGANIZATION/RENAME_ORGANIZATION'
export const UPDATE_USER = 'ORGANIZATION/UPDATE_USER'
export const ADD_USERS = 'ORGANIZATION/ADD_USERS'
export const REMOVE_USER = 'ORGANIZATION/REMOVE_USER'
export const SET_BILLING = 'ORGANIZATION/SET_BILLING'
export const SET_NOTIFY_ME = 'ORGANIZATION/SET_NOTIFY_ME'
export const UPGRADE_ACCOUNT = 'ORGANIZATION/UPGRADE_ACCOUNT'
export const UPGRADE_COLLEAGUES = 'ORGANIZATION/UPGRADE_COLLEAGUES'

export function fetchOrganization() {
  return dispatch => {
    getJson('/jsapi/s/organization').then((organization) => {
      dispatch({
        type: ORGANIZATION_LOADED,
        organization
      })
    })
  }
}

export function changePolicy(policy, value) {
  return dispatch => {
    postJson(`/jsapi/s/organization/policy/${policy}`, { value })
    dispatch({
      type: SET_POLICY,
      policy,
      value
    })
  }
}

export function changeNotifyMe(value) {
  return dispatch => {
    postJson('/jsapi/s/organization/notify', { value })
    dispatch({
      type: SET_NOTIFY_ME,
      value
    })
  }
}

export function renameOrganization(name) {
  return dispatch => {
    postJson('/jsapi/s/organization/rename', { value: name })
    dispatch({
      type: RENAME_ORGANIZATION,
      name
    })
  }
}

export function updateUserDetails(user) {
  return dispatch => {

    postJson(`/jsapi/s/organization/users/${user.get('id')}`, user).then(response => {

      if(response.get('newBilling')) {
        dispatch({
          type: SET_BILLING,
          billing: response.get('newBilling')
        })
      }
    })
    dispatch({
      type: UPDATE_USER,
      user
    })
  }
}

export function addUsers(users) {
  return dispatch => {

    postJson('/jsapi/s/organization/users', users).then(response => {

      dispatch({
        type: ADD_USERS,
        users: response.get('users')
      })
      if(response.get('newBilling')) {
        dispatch({
          type: SET_BILLING,
          billing: response.get('newBilling')
        })
      }
    })
  }
}

export const revokeInvitation = email =>
  dispatch => {
    postJson(`/jsapi/s/organization/cancelinvitation/${email}`)
    dispatch({
      type: REMOVE_USER,
      email
    })
  }

export const setSubscription = token =>
  dispatch =>
    postJson('/jsapi/s/organization/newsubscription', { value: token })
      .then(billing =>
        dispatch({
          type: SET_BILLING,
          billing
        })
      )

export const changeCreditCard = token =>
  dispatch =>
    postJson('/jsapi/s/organization/creditcard', { value: token })
      .then(billing =>
        dispatch({
          type: SET_BILLING,
          billing
        })
      )

export const upgradeAccount = user =>
  dispatch => {
    postJson('/jsapi/s/organization/users', List.of(user))
    dispatch({
      type: UPGRADE_ACCOUNT,
      user
    })
  }

  export const upgradeColleagues = users =>
    dispatch => {
      postJson('/jsapi/s/organization/users', users)
      dispatch({
        type: UPGRADE_COLLEAGUES,
        users
      })
    }
