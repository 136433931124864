import React, { Component } from 'react'
import { connect } from 'react-redux'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import NotificationSystem from 'react-notification-system'
import 'bootstrap/dist/css/bootstrap.css'
import 'todc-bootstrap/dist/css/todc-bootstrap.css'

import DocollabTheme from './DocollabTheme'
import { postJson } from '../../common/services/http'
import { loadAppView, setStripeKey } from '../container-actions'
import InviteDialog from '../../invite/InviteDialog'
import LoadingIndicator from '../../common/components/LoadingIndicator'

class AppContainer extends Component {

  componentDidMount() {

    window.notificationSystem = this.notificationSystem

    postJson('/jsapi/o/session/check').then(response => {

      try {
        window.gapi.auth2.init({
          client_id: response.get('googleClientId')
        })
      } catch (e) {}

      this.props.dispatch(setStripeKey(response.get('stripeKey')))

      if(response.get('user')) {
        this.loadApp(response.get('user'))
      } else {
        this.loadApp()
      }
    })
    .catch(() => this.loadApp())

    if(!localStorage.getItem('referral')) {
      localStorage.setItem('referral', window.location.toString() + '\n' + document.referrer)
    }
  }

  loadApp(user) {
    this.props.dispatch(loadAppView(user))
  }

  render() {
    const { loaded, children } = this.props
    return(
      <MuiThemeProvider muiTheme={getMuiTheme(DocollabTheme)}>
        <div>
          {loaded ?
            <div>{children}</div>
            :
            <LoadingIndicator/>
          }
          <NotificationSystem ref={notificationSystem => this.notificationSystem = notificationSystem}/>
          <InviteDialog/>
          <iframe
            title="docollab-download"
            id="docollab-download-iframe"
            style={{
              width: 1,
              height: 1,
              border: 'none'
            }}
          />
        </div>
      </MuiThemeProvider>
    )
  }
}

export default connect(({container}) => ({
  loaded: container ? container.get('loaded') : false
}))(AppContainer)
