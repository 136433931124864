import Immutable from 'immutable'

import * as ACTION from './project-actions'

const getFileIndex = (state, id) =>
  state.get('files').findIndex(item => item.get('id') === id)

export default function projectReducer(state = null, action) {
  switch(action.type) {
    case ACTION.PROJECT_LOADED: {
      return action.project
    }
    case ACTION.CLEAR_PROJECT: {
      return null
    }
    case ACTION.SET_PROJECT_LOCATION: {
      const s = state
        .set('currentFolderId', action.folderId)
        .set('currentItemId', action.itemId)
        .set('isTaskTree', action.isTaskTree)
        .set('isMembers', action.isMembers)
        .set('isSearch', action.isSearch)
        .updateIn(['files'], files => files.map(file => file.get('folderId') === action.folderId ? file : file.delete('selected')))
      if(action.itemId) {
        const file = s.get('files').find(file => file.get('id') === action.itemId)
        const index = s.get('files').indexOf(file)
        return s
          .delete('filePlaceholder')
          .set('lastSelectedIndex', file.get('index'))
          .setIn(['files', index, 'selected'], true)
      }
      return s
    }
    case ACTION.RENAME_PROJECT:
      return state.set('name', action.name)
    case ACTION.SHOW_FILE_PLACEHOLDER:
      return state.set('filePlaceholder', true)
    case ACTION.ADD_ITEM:
      return state
        .updateIn(['files'], files => files.push(action.item))
    case ACTION.RENAME_FILE: {
      const index = getFileIndex(state, action.id)
      return state.setIn(['files', index, 'name'], action.name)
    }
    case ACTION.UPDATE_FILE_STATUS:
      return state.update('files', files => files.map(file => {
        if(file.get('id') === action.id) {
          return file.set('status', action.status)
        }
        return file
      }))
    case ACTION.REMOVE_FILES: {
      return state
        .updateIn(['files'], files => files
          .filter(f => !action.ids.includes(f.get('id')))
          .groupBy(f => f.get('folderId'))
          .map(f => f.sortBy(f => f.get('index')).map((file, index) => file.set('index', index)))
          .valueSeq()
          .flatten(1)
          .toList()
        )
    }
    case ACTION.MOVE_FILE_TO_POSITION: {
      const fileToMove = state.get('files').find(file => file.get('id') === action.id)
      return state.updateIn(['files'], files => files
        .filter(f => f.get('id') !== action.id)
        .groupBy(f => f.get('folderId'))
        .map((f, folderId) => folderId !== fileToMove.get('folderId') ? f : f
          .sortBy(f => f.get('index'))
          .splice(action.newIndex, 0, fileToMove)
          .map((file, index) => file.set('index', index))
        )
        .valueSeq()
        .flatten(1)
        .toList()
      )
    }
    case ACTION.MOVE_FILES_TO_FOLDER: {
      return state.updateIn(['files'], files => {
        const filesToMove = files
          .filter(f => action.ids.includes(f.get('id')))
          .map(f => f.set('folderId', action.folderId))
        let groups = files
          .filter(f => !action.ids.includes(f.get('id')))
          .groupBy(f => f.get('folderId'))

        if(!groups.has(action.folderId)) {
          groups = groups.set(action.folderId, Immutable.List())
        }

        return groups
          .map((files, folderId) => folderId === action.folderId ? files.splice(0, 0, ...filesToMove.toArray()) : files)
          .map(f => f.sortBy(f => f.get('index')).map((file, index) => file.set('index', index)))
          .valueSeq()
          .flatten(1)
          .toList()
      })
    }
    case ACTION.ADD_MEMBERS: {
      ['members', 'pendingUsers', 'pendingEmails'].forEach(listName =>
        state = state.update(listName, list => list
          .filterNot(m => action.lists.get(listName).map(i => i.get('id')).includes(m.get('id')))
          .concat(action.lists.get(listName))
        )
      )
      return state
    }
    case ACTION.REMOVE_MEMBER: {
      const userId = action.user.get('id');
      ['members', 'pendingUsers', 'pendingEmails'].forEach(listName =>
        state = state.update(listName, list => list.filter(m => m.get('id') !== userId)))
      return state
    }
    case ACTION.SET_OWNER: {
      const newOwner = state.get('members').find(m => m.get('id') === action.userId)
      const isOwner = state.get('isManager') || action.userId === state.get('userId')
      return state
        .set('owner', newOwner)
        .set('isOwner', isOwner)
    }
    case ACTION.TOGGLE_SELECTED_FILE: {
      const file = state.get('files').find(item => item.get('id') === action.id)
      const index = state.get('files').indexOf(file)
      return state
        .set('lastSelectedIndex', file.get('index'))
        .updateIn(['files', index], item => item.get('selected') ? item.remove('selected') : item.set('selected', true))
    }
    case ACTION.TOGGLE_SELECTED_FILE_RANGE: {
      const file = state.get('files').find(item => item.get('id') === action.id)
      const targetIndex = file.get('index')
      if(!state.has('lastSelectedIndex')) {
        return state
          .set('lastSelectedIndex', file.get('index'))
          .updateIn(['files', state.get('files').indexOf(file)], item => item.get('selected') ? item.remove('selected') : item.set('selected', true))
      }
      const sourceIndex = state.get('lastSelectedIndex')
      const folderId = state.get('currentFolderId')
      const selected = state.get('files').get(targetIndex).get('selected')
      return state
        .set('lastSelectedIndex', targetIndex)
        .updateIn(['files'], files => files.map(file => {
          if(file.get('folderId') === folderId &&
            file.get('index') >= Math.min(sourceIndex, targetIndex) &&
            file.get('index') <= Math.max(sourceIndex, targetIndex)) {
            return selected ? file.delete('selected') : file.set('selected', true)
          }
          return file
        }))
    }
    case ACTION.CLEAR_SELECTED_FILES:
      return state ? state
        .delete('lastSelectedIndex')
        .updateIn(['files'], files => files.map(item => item.remove('selected'))) : state
    case ACTION.CLEAR_OTHER_SELECTED_FILES:
      return state
        .set('lastSelectedIndex', action.file.get('index'))
        .updateIn(['files'], files =>
          files.map(file => file.get('id') === action.file.get('id') ? file.set('selected', true) : file.delete('selected')))
    case ACTION.TOGGLE_STAR:
      return state.update('star', star => !star)
    case ACTION.SET_STATUS:
      return state.set('status', action.status)
    default:
      return state
  }
}
