import * as ACTION from './orgadmin-actions'

export default function orgAdminReducer(state = null, action) {
  switch(action.type) {
    case ACTION.ORGANIZATION_LOADED:
      return action.organization
    case ACTION.CLEAR_ORGANIZATION:
      return null
    case ACTION.SET_POLICY:
      return state.setIn(['details', action.policy], action.value)
    case ACTION.SET_NOTIFY_ME:
      return state.set('notify', action.value)
    case ACTION.RENAME_ORGANIZATION:
      return state.setIn(['details', 'name'], action.name)
    case ACTION.UPDATE_USER: {
      const index = state.get('users').findIndex(user => user.get('id') === action.user.get('id'))
      return state.setIn(['users', index], action.user)
    }
    case ACTION.ADD_USERS:
      return state.updateIn(['users'], users => users.concat(action.users))
    case ACTION.REMOVE_USER: {
      const index = state.get('users').findIndex(user => user.get('email') === action.email)
      return state.deleteIn(['users', index])
    }
    case ACTION.SET_BILLING:
      return state.set('billing', action.billing)
    case ACTION.UPGRADE_ACCOUNT:
      return state
        .updateIn(['freeUsers'], freeUsers => freeUsers.filter(u => u.get('id') !== action.user.get('id')))
        .updateIn(['users'], users => users.push(action.user))
    case ACTION.UPGRADE_COLLEAGUES:
      return state
        .updateIn(['freeColleaguesOfAdmin'], colleagues => colleagues.filterNot(c => action.users.includes(c)))
        .updateIn(['users'], users => users.concat(action.users.map(u => u.set('isPending', true))))
    default:
      return state
  }
}
