import React from 'react'
import { CircularProgress } from 'material-ui'

export default function LoadingIndicator(props) {
  return(
    <div style={{
        ...props.style,
        position: props.container ? undefined : 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress size={50}/>
    </div>
  )
}
