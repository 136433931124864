import React from 'react'
import Immutable from 'immutable'
import qs from 'qs'
import { brown200, brown800, indigo500, indigo800, indigo900, lightBlue800, lightBlue900, red900,
  green800, green900, blue900, deepOrange800, deepOrange900, purple800, deepPurple500, deepPurple800 } from 'material-ui/styles/colors'

import { downloadFile } from '../../common/services/http'

export const ACCESS_LEVELS = Immutable.fromJS({
  1: 'Can view',
  3: 'Can edit',
  4: 'Can manage'
})

export const STATUS_ACTIVE = 1
export const STATUS_ARCHIVED = 2
export const STATUS_DELETED = 3

export const ACCESS_LEVEL_IDS = ACCESS_LEVELS.keySeq().map(i => parseInt(i, 10)).toJS()

export const FILE_STATUSES = Immutable.OrderedMap([
  [null, ['None']],
  ['draft', ['Draft', deepOrange800]],
  ['inprogress', ['In progress', lightBlue800]],
  ['completed', ['Completed', indigo800]],
  ['signed', ['Signed', green800]]
])

export const getHasMoreMembers = project =>
  project.get('members').size > 1 ||
  project.get('pendingEmails').size > 0 ||
  project.get('pendingUsers').size > 0

export const getIsManager = project =>
  project.get('isOrganizationManager') || project.get('accessLevel') >= 4

  export const getCanEdit = project =>
    project.get('isOrganizationManager') || project.get('accessLevel') >= 3

export const getFolderFiles = project =>
  project.get('files')
    .filter(item => item.get('folderId') === (project.get('currentFolderId') || null))
    .sortBy(item => item.get('index'))

export const getContentTypeIcon = mime => {
  switch(mime) {
    case 'text/plain':
      return {className: 'fa fa-file-text-o'}
    case 'application/pdf':
      return {className: 'fa fa-file-pdf-o', style: {color: red900}}
    case 'application/zip':
      return {className: 'fa fa-file-archive-o', style: {color: brown800}}
    case 'application/vnd.ms-excel':
    case 'application/vnd.ms-excel.addin.macroenabled.12':
    case 'application/vnd.ms-excel.sheet.binary.macroenabled.12':
    case 'application/vnd.ms-excel.template.macroenabled.12':
    case 'application/vnd.ms-excel.sheet.macroenabled.12':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
      return {className: 'fa fa-file-excel-o', style: {color: green900}}
    case 'application/msword':
    case 'application/vnd.ms-word.document.macroenabled.12':
    case 'application/vnd.ms-word.template.macroenabled.12':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return {className: 'fa fa-file-word-o', style: {color: blue900}}
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.ms-powerpoint.addin.macroenabled.12':
    case 'application/vnd.ms-powerpoint.slide.macroenabled.12':
    case 'application/vnd.ms-powerpoint.presentation.macroenabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroenabled.12':
    case 'application/vnd.ms-powerpoint.template.macroenabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return {className: 'fa fa-file-powerpoint-o', style: {color: deepOrange900}}
    default: {}
  }

  switch(mime.substr(0, mime.indexOf('/'))) {
    case 'text':
      return {className: 'fa fa-file-text-o'}
    case 'image':
      return {className: 'fa fa-file-image-o'}
    case 'audio':
      return {className: 'fa fa-file-audio-o', style: {color: purple800}}
    case 'video':
      return {className: 'fa fa-file-video-o', style: {color: deepPurple800}}
    default: {}
  }

  return {className: 'fa fa-file-o'}
}

export const getItemIcon = item => {
  switch(item.get('type') || item.get('itemType')) {
    case 'project':
      return {className: 'fa fa-tasks', style: {color: deepPurple500}}
    case 'folder':
      return {className: 'fa fa-folder-open', style: {color: brown200}}
    case 'notebook':
      return {className: 'fa fa-book', style: {color: indigo500}}
    case 'document':
      return {className: 'zmdi zmdi-format-subject', style: {color: indigo900}}
    case 'diagram':
      return {className: 'fa fa-object-group', style: {color: lightBlue900}}
    default: {}
  }

  const mime = item.get('mime')

  return getContentTypeIcon(mime)
}

export const getItemLinkTo = (projectId, item) => {
  const itemType = item.get('type')
  if(itemType === 'folder') {
    return `/project/${projectId}?folder=${item.get('id')}`
  }

  return `/project/${projectId}?${qs.stringify({folder: item.get('folderId') || undefined, item: item.get('id')})}`
}

const spreadsheetContentTypes = Immutable.fromJS([
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

])

export const isSpreadsheet = item =>
  item &&
  item.get('type') === 'file' &&
  spreadsheetContentTypes.includes(item.get('mime'))

export const isPdf = item =>
  item &&
  item.get('type') === 'file' &&
  item.get('mime') === 'application/pdf'

export const isDocx = item =>
  item &&
  item.get('type') === 'file' &&
  item.get('mime') === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

export const downloadProjectFile = (projectId, file, projectName, files) => {
  if(files && files.size === 1) {
    file = files.get(0)
    files = undefined
  }
  if(!file) {
    return downloadProject(projectId, projectName, files)
  }
  let filename = file.get('name').replace(/[/\\#]/g,'-')
  switch(file.get('type')) {
    case 'folder':
      return downloadProject(projectId, filename, files, file.get('id'))
    case 'document':
      filename += '.docx'
      break
    default: {}
  }
  downloadFile(`/jsapi/s/projects/${projectId}/files/${file.get('id')}/download/${filename}`)
}

export const downloadProject = (projectId, name, files, folder) => {
  const params = {}
  if(files && files.size) {
    params.selectedIds = files.map(f => f.get('id')).toArray().join(',')
  }
  if(folder) {
    params.folder = folder
  }
  const queryString = qs.stringify(params)
  downloadFile(`/export/project/${projectId}/${name.replace(/[/\\#]/g,'-')}.zip?${queryString}`, true)
}

const browserLeaveConfirm = () => 'Are you sure you want to leave and loose unsaved changes?'

export const setBrowserLeaveConfirm = () =>
  window.onbeforeunload = browserLeaveConfirm

export const FONT_MAP = Immutable.OrderedMap({
  'Andale Mono': 'andale mono,times',
  'Arial': 'arial,helvetica,sans-serif',
  'Arial Black': 'arial black,avant garde',
  'Book Antiqua': 'book antiqua,palatino',
  'Comic Sans MS': 'comic sans ms,sans-serif',
  'Courier New': 'courier new,courier',
  'Georgia': 'georgia,palatino',
  'Helvetica': 'helvetica',
  'Impact': 'impact,chicago',
  'Tahoma': 'tahoma,arial,helvetica,sans-serif',
  'Terminal': 'terminal,monaco',
  'Times New Roman': 'times new roman,times',
  'Trebuchet MS': 'trebuchet ms,geneva',
  'Verdana': 'verdana,geneva'
}).mapEntries(([name, value]) => [value, <span key={name} style={{fontFamily: value}}>{name}</span>])

export const FONT_LIST = FONT_MAP.entrySeq()
  .map(tuple => ({ value: tuple[0], label: tuple[1] }))
  .toJS()

export const FONT_SIZE_LIST = Immutable.List([8, 10, 12, 14, 18, 24, 36])
  .map(size => ({
    value: size,
    label: <span style={{fontSize: `${size}pt`}}>{size}</span> }))
  .toJS()

export const COLOR_LIST = [
  ['#ffffff', '#cccccc', '#c0c0c0', '#999999', '#666666', '#333333', '#000000'],
  ['#ffcccc', '#ff6666', '#ff0000', '#cc0000', '#990000', '#660000', '#330000'],
  ['#ffcc99', '#ff9966', '#ff9900', '#ff6600', '#cc6600', '#993300', '#663300'],
  ['#ffff99', '#ffff66', '#ffcc66', '#ffcc33', '#cc9933', '#996633', '#663333'],
  ['#ffffcc', '#ffff33', '#ffff00', '#ffcc00', '#999900', '#666600', '#333300'],
  ['#99ff99', '#66ff99', '#33ff33', '#33cc00', '#009900', '#006600', '#003300'],
  ['#99ffff', '#33ffff', '#66cccc', '#00cccc', '#339999', '#336666', '#003333'],
  ['#ccffff', '#66ffff', '#33ccff', '#3366ff', '#3333ff', '#000099', '#000066'],
  ['#ccccff', '#9999ff', '#6666cc', '#6633ff', '#6600cc', '#333399', '#330099'],
  ['#ffccff', '#ff99ff', '#cc66cc', '#cc33cc', '#993399', '#663366', '#330033']
]

export const SYMBOL_LIST = [
  ['&#945', '&#946', '&#947', '&#948', '&#949', '&#950', '&#951', '&#952', '&#953'],
  ['&#954', '&#955', '&#956', '&#957', '&#958', '&#959', '&#960', '&#961', '&#962'],
  ['&#963', '&#964', '&#965', '&#966', '&#967', '&#968', '&#969', '&#977', '&#978'],
  ['&#982', '&#913', '&#914', '&#915', '&#916', '&#917', '&#918', '&#919', '&#920'],
  ['&#921', '&#922', '&#923', '&#924', '&#925', '&#926', '&#927', '&#928', '&#929'],
  ['&#931', '&#932', '&#933', '&#934', '&#935', '&#936', '&#937', '&#8592', '&#8593'],
  ['&#8594', '&#8595', '&#8596', '&#186', '&#169', '&#174', '&#8482']
]

export const sortByNameFn = (a, b) => {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()
  switch(true) {
    case nameA < nameB: return -1
    case nameA > nameB: return 1
    default: return 0
  }
}
