import { combineReducers } from 'redux'
import is from 'is_js'

import nav from './container/components/desktop/nav-reducer'
import containerReducer from './container/container-reducer'
import inviteReducer from './invite/invite-reducer'
import projectReducer from './project/project-reducer'
import orgAdminReducer from './orgadmin/orgadmin-reducer'

export default function createReducer() {

  const platformReducers = is.desktop() ? {nav} : {}

  return combineReducers({
    container: containerReducer,
    invite: inviteReducer,
    project: projectReducer,
    orgAdmin: orgAdminReducer,
    ...platformReducers
  })
}
