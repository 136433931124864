let _textDialog = undefined
let _confirmDialog = undefined
let _paragraphDialog = undefined

export function setTextDialog(textDialog) {
  // if(_textDialog) {
  //   throw Error("Text dialog already exists")
  // }
  _textDialog = textDialog
}

export function setConfirmDialog(confirmDialog) {
  // if(_confirmDialog) {
  //   throw Error("Confirm dialog already exists")
  // }
  _confirmDialog = confirmDialog
}

export function setParagraphDialog(paragraphDialog) {
  // if(_paragraphDialog) {
  //   throw Error("Paragraph dialog already exists")
  // }
  _paragraphDialog = paragraphDialog
}

export function promptTextDialog(options) {
  return _textDialog.open(options)
}

export function promptConfirmDialog(options) {
  return _confirmDialog.open(options)
}

export function promptParagraphDialog(options) {
  return _paragraphDialog.open(options)
}
